import { useAuthState } from "~/stores/auth";

export default defineNuxtRouteMiddleware((to, from) => {
    // Get auth state
    const auth = useAuthState()

    const nuxtApp = useNuxtApp()
    const { ssrContext } = nuxtApp

    console.log({ to, from })

    if (ssrContext) {
        console.log(`Auth state middleware running in SSR. Skipping..`)
        return
    } else { console.log(`Auth state middleware running client-side..`) }

    // if (auth.userTenantAdminTotalTenants > 1) {
    //     return navigateTo('/')
    // }

    if (!auth.isAuthed) {
        return navigateTo('/login')
    }

    if (auth.userIsTenantAdmin) {
        return navigateTo('/app/tenant-admin')
    }

    if (auth.isLoggedIn) {
        return navigateTo('/account')
    }

    return navigateTo('/login')

    // let authIsInitiated = false

    // const checkAuthInit = async () => {
    //     let user = await nuxtApp.$firebaseAuth.getCurrentUser()
    //     console.log(`Current user: `, user)
    //     if (nuxtApp.$firebaseAuth) {
    //         console.log(`Firebase auth is initialised.`)
    //         return true
    //     } else {
    //         console.log(`Firebase auth is not initialised. Waiting..`)
    //         return false

    //     }
    // }

    // const maxIterations = 10
    // let iterations = 1
    // do {
    //     // code block to be executed
    //     let isInit = false
    //     if (iterations > maxIterations) {
    //         console.log(`Max iterations reached. Aborting..`)
    //         break
    //     }

    //     if (isInit) {
    //         authIsInitiated = true
    //         console.log(`Auth is now initiated. Stopping loop.`)
    //         break
    //     } else {
    //         console.log(`Auth is not initiated. Waiting..`)
    //         // let r = nextTick(() => {
    //         //     isInit = checkAuthInit()
    //         //     return isInit
    //         // })
    //         let r = checkAuthInit()
    //         console.log(`R: ${r}`)
    //         iterations++
    //     }

    // }
    // while (!authIsInitiated);

    // console.log(`############# Loop ended with result: ${authIsInitiated}`)


    // nextTick(() => {
    //     // We need to wait for the user to be authenticated 
    //     // before we can check if they are a tenant admin
    //     if (auth.userIsTenantAdmin) {
    //         console.log(`User is tenant admin. Redirecting to tenant admin dashboard..`)
    //         return navigateTo('/tenant-admin')
    //     } else { console.log(`User is not tenant admin. Redirecting to tenant user dashboard..`) }
    // })

    console.log('--- Auth Middleware ---');
    console.log({ to, from, auth })
})
